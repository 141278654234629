import dynamic from 'next/dynamic'
import type { RouteProps } from '../../libs/routing/routing'
import type { LayoutRenderer, RoutingPageProps } from '../../libs/routing/RoutingPage'

export enum RouteName {
	article = 'article',
	articleDiscussion = 'articleDiscussion',
	articlesPage = 'articlesPage',
	author = 'author',
	authorsPage = 'authorsPage',
	error404 = 'error404',
	feedPage = 'feedPage',
	home = 'home',
	kviff = 'kviff',
	oidc = 'oidc',
	page = 'page',
	points = 'points',
	products = 'products',
	search = 'search',
	tag = 'tag',
	user = 'user',
	venue = 'venue',
}

export type RoutingModule<T extends RouteName> = {
	default: React.ComponentType<RouteProps<T>>
	renderLayout?: LayoutRenderer | undefined
}

export type InitialComponentProps<T extends RouteName> = {
	Component?: React.ComponentType<RouteProps<T>> | undefined
	renderLayout?: LayoutRenderer | undefined
	props: RoutingPageProps
	routeData: RouteProps<T>
}

const renderJSX = (children: React.ReactNode) => children

const createInitialComponent = <T extends RouteName>(m: RoutingModule<T>) => {
	return function InitialComponent(props: InitialComponentProps<T>) {
		const Component: React.ComponentType<RouteProps<T>> = props.Component ?? m.default

		const renderLayout = props.renderLayout ?? m.renderLayout ?? renderJSX

		const routeData = props.Component ? props.routeData : props.props.routeData

		const componentProps = { page: routeData } as RouteProps<T>

		return <>{renderLayout(<Component {...componentProps} />, props.props)}</>
	}
}

const createFollow = <T extends RouteName>(m: RoutingModule<T>) => ({
	Component: m.default,
	renderLayout: m.renderLayout,
})

export const ROUTING_COMPONENTS = {
	[RouteName.article]: {
		Initial: dynamic<InitialComponentProps<RouteName.article>>(() =>
			import('../../app/routes/article').then((m: RoutingModule<RouteName.article>) =>
				createInitialComponent(m)
			)
		),
		follow: () =>
			import('../../app/routes/article').then((m: RoutingModule<RouteName.article>) =>
				createFollow(m)
			),
	} as const,
	[RouteName.articleDiscussion]: {
		Initial: dynamic<InitialComponentProps<RouteName.articleDiscussion>>(() =>
			import('../../app/routes/articleDiscussion').then(
				(m: RoutingModule<RouteName.articleDiscussion>) => createInitialComponent(m)
			)
		),
		follow: () =>
			import('../../app/routes/articleDiscussion').then(
				(m: RoutingModule<RouteName.articleDiscussion>) => createFollow(m)
			),
	} as const,
	[RouteName.articlesPage]: {
		Initial: dynamic<InitialComponentProps<RouteName.articlesPage>>(() =>
			import('../../app/routes/articlesPage').then((m: RoutingModule<RouteName.articlesPage>) =>
				createInitialComponent(m)
			)
		),
		follow: () =>
			import('../../app/routes/articlesPage').then((m: RoutingModule<RouteName.articlesPage>) =>
				createFollow(m)
			),
	} as const,
	[RouteName.author]: {
		Initial: dynamic<InitialComponentProps<RouteName.author>>(() =>
			import('../../app/routes/author').then((m: RoutingModule<RouteName.author>) =>
				createInitialComponent(m)
			)
		),
		follow: () =>
			import('../../app/routes/author').then((m: RoutingModule<RouteName.author>) =>
				createFollow(m)
			),
	} as const,
	[RouteName.authorsPage]: {
		Initial: dynamic<InitialComponentProps<RouteName.authorsPage>>(() =>
			import('../../app/routes/authorsPage').then((m: RoutingModule<RouteName.authorsPage>) =>
				createInitialComponent(m)
			)
		),
		follow: () =>
			import('../../app/routes/authorsPage').then((m: RoutingModule<RouteName.authorsPage>) =>
				createFollow(m)
			),
	} as const,
	[RouteName.error404]: {
		Initial: dynamic<InitialComponentProps<RouteName.error404>>(() =>
			import('../../app/routes/error404').then((m: RoutingModule<RouteName.error404>) =>
				createInitialComponent(m)
			)
		),
		follow: () =>
			import('../../app/routes/error404').then((m: RoutingModule<RouteName.error404>) =>
				createFollow(m)
			),
	} as const,
	[RouteName.feedPage]: {
		Initial: dynamic<InitialComponentProps<RouteName.feedPage>>(() =>
			import('../../app/routes/feedPage').then((m: RoutingModule<RouteName.feedPage>) =>
				createInitialComponent(m)
			)
		),
		follow: () =>
			import('../../app/routes/feedPage').then((m: RoutingModule<RouteName.feedPage>) =>
				createFollow(m)
			),
	} as const,
	[RouteName.home]: {
		Initial: dynamic<InitialComponentProps<RouteName.home>>(() =>
			import('../../app/routes/home').then((m: RoutingModule<RouteName.home>) =>
				createInitialComponent(m)
			)
		),
		follow: () =>
			import('../../app/routes/home').then((m: RoutingModule<RouteName.home>) => createFollow(m)),
	} as const,
	[RouteName.kviff]: {
		Initial: dynamic<InitialComponentProps<RouteName.kviff>>(() =>
			import('../../app/routes/kviff').then((m: RoutingModule<RouteName.kviff>) =>
				createInitialComponent(m)
			)
		),
		follow: () =>
			import('../../app/routes/kviff').then((m: RoutingModule<RouteName.kviff>) => createFollow(m)),
	} as const,
	[RouteName.oidc]: {
		Initial: dynamic<InitialComponentProps<RouteName.oidc>>(() =>
			import('../../app/routes/oidc').then((m: RoutingModule<RouteName.oidc>) =>
				createInitialComponent(m)
			)
		),
		follow: () =>
			import('../../app/routes/oidc').then((m: RoutingModule<RouteName.oidc>) => createFollow(m)),
	} as const,
	[RouteName.page]: {
		Initial: dynamic<InitialComponentProps<RouteName.page>>(() =>
			import('../../app/routes/page').then((m: RoutingModule<RouteName.page>) =>
				createInitialComponent(m)
			)
		),
		follow: () =>
			import('../../app/routes/page').then((m: RoutingModule<RouteName.page>) => createFollow(m)),
	} as const,
	[RouteName.points]: {
		Initial: dynamic<InitialComponentProps<RouteName.points>>(() =>
			import('../../app/routes/points').then((m: RoutingModule<RouteName.points>) =>
				createInitialComponent(m)
			)
		),
		follow: () =>
			import('../../app/routes/points').then((m: RoutingModule<RouteName.points>) =>
				createFollow(m)
			),
	} as const,
	[RouteName.products]: {
		Initial: dynamic<InitialComponentProps<RouteName.products>>(() =>
			import('../../app/routes/products').then((m: RoutingModule<RouteName.products>) =>
				createInitialComponent(m)
			)
		),
		follow: () =>
			import('../../app/routes/products').then((m: RoutingModule<RouteName.products>) =>
				createFollow(m)
			),
	} as const,
	[RouteName.search]: {
		Initial: dynamic<InitialComponentProps<RouteName.search>>(() =>
			import('../../app/routes/search').then((m: RoutingModule<RouteName.search>) =>
				createInitialComponent(m)
			)
		),
		follow: () =>
			import('../../app/routes/search').then((m: RoutingModule<RouteName.search>) =>
				createFollow(m)
			),
	} as const,
	[RouteName.tag]: {
		Initial: dynamic<InitialComponentProps<RouteName.tag>>(() =>
			import('../../app/routes/tag').then((m: RoutingModule<RouteName.tag>) =>
				createInitialComponent(m)
			)
		),
		follow: () =>
			import('../../app/routes/tag').then((m: RoutingModule<RouteName.tag>) => createFollow(m)),
	} as const,
	[RouteName.user]: {
		Initial: dynamic<InitialComponentProps<RouteName.user>>(() =>
			import('../../app/routes/user').then((m: RoutingModule<RouteName.user>) =>
				createInitialComponent(m)
			)
		),
		follow: () =>
			import('../../app/routes/user').then((m: RoutingModule<RouteName.user>) => createFollow(m)),
	} as const,
	[RouteName.venue]: {
		Initial: dynamic<InitialComponentProps<RouteName.venue>>(() =>
			import('../../app/routes/venue').then((m: RoutingModule<RouteName.venue>) =>
				createInitialComponent(m)
			)
		),
		follow: () =>
			import('../../app/routes/venue').then((m: RoutingModule<RouteName.venue>) => createFollow(m)),
	} as const,
} as const

export type RoutingComponents = typeof ROUTING_COMPONENTS
