/* eslint-disable @typescript-eslint/no-explicit-any */
import type { InferGetServerSidePropsType } from 'next'
import React from 'react'
import { Counter } from '../../app/components/Counter'
import { useRefreshGtmService } from '../../app/utils/useRefreshGtmService'
import { ROUTING_COMPONENTS } from '../../generated/routing/components'
import type { createServerSidePropsRouting } from './createServerSidePropsRouting'
import { SharedContext } from './sharedContext'

export type RoutingPageProps = InferGetServerSidePropsType<
	ReturnType<typeof createServerSidePropsRouting>
>

type AnyRouteData = any

export type LayoutRenderer = (children: React.ReactNode, props: RoutingPageProps) => React.ReactNode

const defaultLayoutRenderer: LayoutRenderer = (children) => (
	<>
		<p>
			defaultLayoutRenderer <Counter />
		</p>
		{children}
	</>
)

type HistoryPage = {
	id: string
	loader: () => Promise<unknown>
	props: AnyRouteData
} & (
	| {
			type: 'initial'
			follow: null
	  }
	| {
			type: 'following'
			follow: null | {
				Component: React.ComponentType<any>
				renderLayout: LayoutRenderer
			}
	  }
)

const uid = () => String('uid_' + (Math.random() + new Date().getTime()))

export function RoutingPage(props: RoutingPageProps) {
	const r = props.metaData.info.route.route as keyof typeof ROUTING_COMPONENTS
	const routeData = props.routeData as AnyRouteData
	const [initialProps] = React.useState(props)
	const [InitialPage] = React.useState<any>(() => ROUTING_COMPONENTS[r].Initial)
	const pageLoader = ROUTING_COMPONENTS[r].follow

	const [pages, setPages] = React.useState<HistoryPage[]>(() => {
		const id = 'initial'
		return [
			{
				id,
				type: 'initial',
				loader: pageLoader,
				follow: null,
				props: routeData,
			},
		]
	})

	React.useEffect(() => {
		const lastPage = pages[pages.length - 1]

		if (!lastPage || routeData !== lastPage.props || pageLoader !== lastPage.loader) {
			const id = uid()
			setPages((old) => {
				const sameComponent = pages.find((page) => page.loader === pageLoader)
				return [
					...old,
					{
						id,
						type: 'following',
						loader: pageLoader,
						follow: sameComponent ? sameComponent.follow : null,
						props: routeData,
					},
				]
			})
			pageLoader().then((m) => {
				setPages((old) => {
					return old.map((item) => {
						if (item.id === id) {
							return { ...item, type: 'following', follow: m } as HistoryPage
						}
						return item
					})
				})
			})
		}
	}, [pageLoader, routeData, pages])

	const loadedPages = pages.filter((page) => page.type === 'initial' || page.follow)
	const currentPage = loadedPages[loadedPages.length - 1]

	useRefreshGtmService([currentPage])

	React.useEffect(() => {
		window.history.scrollRestoration = 'manual'
	}, [])

	React.useEffect(() => {
		const w = window as { noScroll?: number }
		if (w.noScroll) {
			w.noScroll--
		} else {
			window.scrollTo({ top: 0, left: 0, behavior: 'instant' as ScrollBehavior })
		}
		window.dispatchEvent(new CustomEvent('routing_finish', { detail: currentPage }))
	}, [currentPage])

	if (!currentPage) {
		return null
	}

	const Component = currentPage.type === 'following' ? currentPage.follow?.Component : undefined

	const render =
		currentPage.type === 'following'
			? currentPage.follow?.renderLayout ?? defaultLayoutRenderer
			: undefined

	const currentRouteData = currentPage.type === 'following' ? currentPage.props : undefined

	const pageProps = Component ? props : initialProps

	return (
		<>
			<SharedContext.Provider value={props.sharedData}>
				<InitialPage
					props={pageProps}
					routeData={currentRouteData}
					Component={Component}
					renderLayout={render}
				/>
			</SharedContext.Provider>
		</>
	)
}
