import React from 'react'
import type { SharedData } from './createServerSidePropsRouting'

export const SharedContext = React.createContext<null | SharedData>(null)

export function useSharedContext() {
	const value = React.useContext(SharedContext)
	if (!value) {
		throw new Error('Missing SharedContext.')
	}
	return value
}
