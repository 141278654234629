import React from 'react'

export function Counter() {
	const [n, setN] = React.useState(0)

	React.useEffect(() => {
		const i = setInterval(() => {
			setN((n) => n + 1)
		}, 500)
		return () => clearInterval(i)
	}, [])

	return <code>{n}</code>
}
